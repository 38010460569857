<template>
  <collapse-card
    :model-value="true"
  >
    <template #title>
      <span class="mr-2 text-sm font-medium">
        Sample Details
      </span>
    </template>
    <template #cardContent>
      <div class="py-2 px-4">
        <div class="grid grid-cols-2 gap-x-10 gap-y-4 mb-4">
          <base-input
            v-model="submissionForm.contractualSampleAmount"
            type="text"
            container-class="flex"
            label="Contractual Sample Amount"
            label-class="label-w-46"
            disabled
          />
          <base-input
            v-model="submissionForm.contractualSampleNotes"
            text-area
            rows="3"
            container-class="flex"
            label="Contractual Sample Notes"
            label-class="label-w-46"
            disabled
          />
        </div>
        <h6 class="w-full text-center text-black text-sm font-semibold mb-3">
          Sample Received Details
        </h6>
        <div class="grid grid-cols-2 gap-x-10 gap-y-4 mb-4">
          <base-input
            v-if="trackingNumberFieldState !== INVISIBLE"
            v-model="submissionForm.trackingNumber"
            type="text"
            container-class="flex items-center"
            label="Tracking Number"
            label-class="label-w-46"
            :error="submissionFormValidations.trackingNumber"
            :is-required="trackingNumberFieldState === REQUIRED"
          />
          <base-input
            v-if="deliveryCompanyFieldState !== INVISIBLE"
            v-model="submissionForm.deliveryCompany"
            type="text"
            container-class="flex items-center"
            label="Delivery Company"
            label-class="label-w-46"
            :error="submissionFormValidations.deliveryCompany"
            :is-required="deliveryCompanyFieldState === REQUIRED"
          />
          <base-input
            v-if="currentSampleCountFieldState !== INVISIBLE"
            v-model="submissionForm.currentSampleCount"
            type="text"
            container-class="flex items-center"
            label="Current Sample Count"
            label-class="label-w-46"
            :error="submissionFormValidations.currentSampleCount"
            :is-required="currentSampleCountFieldState === REQUIRED"
            @blur="calculateTotalSampleCountValue()"
          />
          <div class="flex items-center">
            <base-input
              v-model="submissionForm.totalSampleCount"
              type="text"
              container-class="flex items-center mr-3"
              label="Total Sample Count"
              label-class="label-w-46"
              :error="submissionFormValidations.totalSampleCount"
              :is-required="submissionFormValidations.totalSampleCount === REQUIRED_FIELD"
              :disabled="totalSampleCountDisabled"
            />
            <div>
              <base-button
                variant="btn-primary"
                size="py-1.5 px-2.5"
                class="flex items-center"
                text="Edit"
                type="button"
                :disabled="!totalSampleCountDisabled"
                @click="enableTotalSampleCount()"
              />
            </div>
          </div>
          <base-date-picker
            v-if="sampleReceivedDateFieldState !== INVISIBLE"
            v-model="submissionForm.sampleReceivedDate"
            container-class="w-full flex text-sm"
            label-class="label-w-46"
            label="Sample Received Date"
            mode="date"
          />
          <base-select
            v-model="submissionForm.returnSample"
            class="flex items-center text-sm text-left"
            placeholder="-- SELECT --"
            label="Return Sample"
            label-class="label-w-46"
            :error="submissionFormValidations.returnSample"
            :is-required="returnSampleFieldState === REQUIRED"
            :options="returnSampleOptions"
            show-default-option
          />
        </div>
      </div>
    </template>
  </collapse-card>
</template>

<script>
import { computed, defineAsyncComponent, ref, watch, onMounted } from 'vue';
import useSubmissionForm from './submissionForm.js';
import { BAC_REVIEW_STEP, CONCEPT_STAGE, CONTRACTUAL_STAGE, EDITABLE, FINAL_STAGE, GENERIC_SUBMISSION_TYPE, INTERACTIVE_SUBMISSION_TYPE, INVISIBLE, LICENSEE_STEP, OPA_SUBMISSION_TYPE, PRESS_RELEASE_SUBMISSION_TYPE, PRE_PRODUCTION_STAGE, PRINT_ON_DEMAND_SAMPLE_METARIAL, PRINT_ON_DEMAND_SUBMISSION_TYPE, PRODUCTION_STAGE, REQUIRED, STANDARD_SUBMISSION_TYPE } from '@/constants/submissions.js';
import { REQUIRED_FIELD } from '@/constants/validationMessages.js';
import { useStore } from 'vuex';

export default {
    name: 'SampleDetails',

    components: {
        CollapseCard: defineAsyncComponent(() => import('@/components/CollapseCard.vue')),
        BaseInput: defineAsyncComponent(() => import('@/components/generic-components/BaseInput.vue')),
        BaseButton: defineAsyncComponent(() => import('@/components/generic-components/BaseButton.vue')),
        BaseSelect: defineAsyncComponent(() => import('@/components/generic-components/BaseSelect.vue')),
        BaseDatePicker: defineAsyncComponent(() => import('@/components/generic-components/BaseDatePicker.vue'))
    },

    setup () {
        const { submissionForm, submissionFormMode, updateSubmissionForm, submissionFormValidations } = useSubmissionForm();
        const store = useStore();

        const persistedTotalSampleCount = ref(0);
        const fetchedSubmissionContracualSamplesDetails = computed(() => store.getters['baLicensee/getDrmSubmissionContractualSamplesList']);

        onMounted(() => {
            persistedTotalSampleCount.value = submissionForm.totalSampleCount;
        });

        const calculateTotalSampleCountValue = () => {
            if (persistedTotalSampleCount.value !== 0) {
                if (submissionForm.currentStageName === PRE_PRODUCTION_STAGE) submissionForm.totalSampleCount = (parseInt(submissionForm.preProdSampleCount) || 0) + (parseInt(submissionForm.currentSampleCount) || 0);
                else if (submissionForm.currentStageName === PRODUCTION_STAGE) submissionForm.totalSampleCount = (parseInt(submissionForm.prodSampleCount) || 0) + (parseInt(submissionForm.currentSampleCount) || 0);
                else submissionForm.totalSampleCount = (parseInt(submissionForm.sampleCount) || 0) + (parseInt(submissionForm.currentSampleCount) || 0); // (pre prod) sample count for (pre production).
                return submissionForm.totalSampleCount;
            } else {
                submissionForm.totalSampleCount = (parseInt(submissionForm.currentSampleCount) || 0);
                return submissionForm.totalSampleCount;
            }
        };

        // const samplePlaceHoldByCount = () => {
        //     if (submissionForm.currentStageName === CONTRACTUAL_STAGE && submissionForm.currentStepName === LICENSEE_STEP) {
        //         if (parseInt(submissionForm.totalSampleCount) < parseInt(submissionForm.contractualSampleAmount)) {
        //             submissionForm.placeSampleHold = 'Y';
        //         } else {
        //             submissionForm.placeSampleHold = 'N';
        //         }
        //     }
        // };

        const contractualSampleAmountValuePopulation = () => {
            submissionForm.contractualSampleAmount = fetchedSubmissionContracualSamplesDetails?.value?.contractualSamplesQty;
            submissionForm.contractualSampleNotes = fetchedSubmissionContracualSamplesDetails?.value?.baNotes;
        };

        onMounted(() => {
            if (submissionFormMode.value === 'NEW') {
                contractualSampleAmountValuePopulation();
            }
            // samplePlaceHoldByCount();
        });

        const currentSampleCountErrText = ref('');
        watch(
            () => submissionForm.currentSampleCount,
            () => {
                if (parseInt(submissionForm.currentSampleCount) === 0 && submissionForm.materialsSupplied === PRINT_ON_DEMAND_SAMPLE_METARIAL) {
                    currentSampleCountErrText.value = 'Invalid Value';
                    submissionFormValidations.currentSampleCount = 'Invalid Value';
                } else {
                    currentSampleCountErrText.value = '';
                    submissionFormValidations.currentSampleCount = '';
                }
                // samplePlaceHoldByCount();
            }, { deep: true, immediate: true }
        );

        // sample details validations logic
        const deliveryCompanyFieldState = computed(() => {
            if (submissionForm.physicalSampleIncluded === 'Yes') {
                if ([STANDARD_SUBMISSION_TYPE].includes(submissionForm.submissionTypeName)) {
                    return (submissionForm.currentStepName === LICENSEE_STEP || submissionFormMode.value === 'NEW') ? REQUIRED : EDITABLE;
                } else if ([GENERIC_SUBMISSION_TYPE].includes(submissionForm.submissionTypeName)) {
                    if ([CONCEPT_STAGE, CONTRACTUAL_STAGE].includes(submissionForm.currentStageName)) {
                        return REQUIRED;
                    } else if (submissionForm.currentStep === LICENSEE_STEP || submissionFormMode.value === 'NEW') {
                        return REQUIRED;
                    } else {
                        return EDITABLE;
                    }
                } else if (submissionForm.submissionTypeName === INTERACTIVE_SUBMISSION_TYPE) {
                    return submissionForm.currentStageName === FINAL_STAGE ? REQUIRED : EDITABLE;
                } else if (PRESS_RELEASE_SUBMISSION_TYPE === submissionForm.submissionTypeName) {
                    return INVISIBLE;
                } else if (submissionForm.submissionTypeName === OPA_SUBMISSION_TYPE) {
                    return submissionForm.currentStepName === LICENSEE_STEP || submissionFormMode.value === 'NEW' ? REQUIRED : EDITABLE;
                } else if (submissionForm.submissionTypeName === PRINT_ON_DEMAND_SUBMISSION_TYPE) {
                    return submissionForm.currentStepName === LICENSEE_STEP || submissionFormMode.value === 'NEW' ? REQUIRED : EDITABLE;
                } else {
                    return EDITABLE;
                }
            } else {
                return INVISIBLE;
            }
        });

        const trackingNumberFieldState = computed(() => {
            if (submissionForm.physicalSampleIncluded === 'Yes') {
                if ([STANDARD_SUBMISSION_TYPE].includes(submissionForm.submissionTypeName)) {
                    return (submissionForm.currentStepName === LICENSEE_STEP || submissionFormMode.value === 'NEW') ? REQUIRED : EDITABLE;
                } else if ([GENERIC_SUBMISSION_TYPE].includes(submissionForm.submissionTypeName)) {
                    if ([CONCEPT_STAGE, CONTRACTUAL_STAGE].includes(submissionForm.currentStageName)) {
                        return REQUIRED;
                    } else if (submissionForm.currentStep === LICENSEE_STEP || submissionFormMode.value === 'NEW') {
                        return REQUIRED;
                    } else {
                        return EDITABLE;
                    }
                } else if (submissionForm.submissionTypeName === INTERACTIVE_SUBMISSION_TYPE) {
                    return submissionForm.currentStageName === FINAL_STAGE ? REQUIRED : EDITABLE;
                } else if ([OPA_SUBMISSION_TYPE, PRINT_ON_DEMAND_SUBMISSION_TYPE].includes(submissionForm.submissionTypeName)) {
                    return submissionForm.currentStepName === LICENSEE_STEP || submissionFormMode.value === 'NEW' ? REQUIRED : EDITABLE;
                } else {
                    return INVISIBLE;
                }
            } else {
                return INVISIBLE;
            }
        });

        const returnSampleFieldState = computed(() => {
            if (submissionForm.physicalSampleIncluded === 'Yes') {
                if ([STANDARD_SUBMISSION_TYPE, GENERIC_SUBMISSION_TYPE].includes(submissionForm.submissionTypeName)) {
                    return submissionForm.currentStepName === LICENSEE_STEP ? REQUIRED : EDITABLE;
                } else if (OPA_SUBMISSION_TYPE === submissionForm.submissionTypeName) {
                    return EDITABLE;
                } else if (PRESS_RELEASE_SUBMISSION_TYPE !== submissionForm.submissionTypeName) {
                    return REQUIRED;
                } else {
                    return EDITABLE;
                }
            } else {
                return INVISIBLE;
            }
        });

        const sampleReceivedDateFieldState = computed(() => {
            if (submissionForm.physicalSampleIncluded === 'Yes') {
                // while creating new submission or submission is Draft..
                if (submissionFormMode.value === 'NEW' || submissionForm.submissionStatus === 'Draft') {
                    return INVISIBLE;
                } else if ([STANDARD_SUBMISSION_TYPE].includes(submissionForm.submissionTypeName)) {
                    return submissionForm.currentStepName === LICENSEE_STEP ? INVISIBLE : EDITABLE;
                } else if ([INTERACTIVE_SUBMISSION_TYPE].includes(submissionForm.submissionTypeName)) {
                    return submissionForm.currentStageName === CONCEPT_STAGE ? EDITABLE : INVISIBLE;
                } else if ([GENERIC_SUBMISSION_TYPE].includes(submissionForm.submissionTypeName)) {
                    if (submissionForm.currentStepName === LICENSEE_STEP) {
                        return submissionForm.currentStageName === CONCEPT_STAGE ? EDITABLE : INVISIBLE;
                    } else {
                        return EDITABLE;
                    }
                } else if ([OPA_SUBMISSION_TYPE].includes(submissionForm.submissionTypeName)) {
                    return EDITABLE;
                } else if ([PRINT_ON_DEMAND_SUBMISSION_TYPE].includes(submissionForm.submissionTypeName)) {
                    return submissionForm.currentStepName === LICENSEE_STEP ? INVISIBLE : EDITABLE;
                } else {
                    return INVISIBLE;
                }
            } else {
                return INVISIBLE;
            }
        });

        const currentSampleCountFieldState = computed(() => {
            if (submissionForm.physicalSampleIncluded === 'Yes') {
                if ([STANDARD_SUBMISSION_TYPE, OPA_SUBMISSION_TYPE].includes(submissionForm.submissionTypeName)) {
                    if (submissionForm.submissionTypeName === STANDARD_SUBMISSION_TYPE && submissionForm.materialsSupplied === PRINT_ON_DEMAND_SAMPLE_METARIAL) {
                        return (submissionForm.currentStepName === LICENSEE_STEP || submissionFormMode.value !== 'DRAFT' || submissionForm.submissionStatus === 'Draft') && (!submissionForm?.totalSampleCount || submissionForm?.totalSampleCount === '' || submissionForm?.totalSampleCount === 0) ? REQUIRED : EDITABLE;
                    } else {
                        if (submissionForm.currentStepName === BAC_REVIEW_STEP) {
                            return EDITABLE;
                        } else {
                            return submissionForm.currentStepName === LICENSEE_STEP || submissionFormMode.value === 'NEW' ? REQUIRED : EDITABLE;
                        }
                    }
                } else if ([GENERIC_SUBMISSION_TYPE].includes(submissionForm.submissionTypeName)) {
                    if (submissionForm.currentStepName === LICENSEE_STEP) {
                        return [CONCEPT_STAGE, PRE_PRODUCTION_STAGE, PRODUCTION_STAGE, CONTRACTUAL_STAGE].includes(submissionForm.currentStageName) ? REQUIRED : EDITABLE;
                    } else if (submissionFormMode.value === 'NEW') {
                        return REQUIRED;
                    } else {
                        return EDITABLE;
                    }
                } else if ([INTERACTIVE_SUBMISSION_TYPE].includes(submissionForm.submissionTypeName)) {
                    return [FINAL_STAGE].includes(submissionForm.currentStageName) ? REQUIRED : EDITABLE;
                } else {
                    return INVISIBLE;
                }
            } else {
                return INVISIBLE;
            }
        });

        watch(
            () => [submissionForm.deliveryCompany, submissionForm.trackingNumber, submissionForm.returnSample, submissionForm.currentSampleCount],
            () => {
                submissionFormValidations.deliveryCompany = deliveryCompanyFieldState.value === REQUIRED && !submissionForm.deliveryCompany ? REQUIRED_FIELD : '';
                submissionFormValidations.trackingNumber = trackingNumberFieldState.value === REQUIRED && !submissionForm.trackingNumber ? REQUIRED_FIELD : '';
                submissionFormValidations.returnSample = returnSampleFieldState.value === REQUIRED && !submissionForm.returnSample ? REQUIRED_FIELD : '';
                submissionFormValidations.currentSampleCount = currentSampleCountFieldState.value === REQUIRED && !submissionForm.currentSampleCount ? REQUIRED_FIELD : '';
            },
            {
                deep: true,
                immediate: true
            }
        );

        // sample details logic
        const returnSampleOptions = ref([
            'Yes',
            'No'
        ]);
        const totalSampleCountDisabled = ref(true);
        const enableTotalSampleCount = () => {
            totalSampleCountDisabled.value = false;
        };
        watch(
            () => submissionForm.physicalSampleIncluded,
            (__new, __old) => {
                updateSubmissionForm({
                    trackingNumber: '',
                    deliveryCompany: '',
                    currentSampleCount: '',
                    totalSampleCount: '',
                    returnSample: null
                });
            }
        );

        return {
            REQUIRED,
            INVISIBLE,
            // sample details validations
            submissionFormValidations,
            deliveryCompanyFieldState,
            trackingNumberFieldState,
            currentSampleCountFieldState,
            returnSampleFieldState,
            sampleReceivedDateFieldState,
            // sample details
            submissionForm,
            returnSampleOptions,
            totalSampleCountDisabled,
            enableTotalSampleCount,
            calculateTotalSampleCountValue,
            currentSampleCountErrText,
            REQUIRED_FIELD
        };
    }
};
</script>
